import React from 'react';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import NavThree from '../../components/NavThree';
import SliderOne from '../../components/SliderOne';
import BlogThree from '../../components/BlogThree';

import CourseCatThree from '../../components/CourseCatThree';
import CourseCatOne from '../../components/CourseCatOne';
import CallToActionOne from '../../components/CallToActionOne';
import CallToActionTwo from '../../components/CallToActionTwo';
import TestimonialOne from '../../components/TestimonialOne';
import VideoThree from '../../components/VideoThree';

const HomePageThree = () => {
  return (
    <Layout pageTitle='Schools | Lagos University Teaching Hospital'>
      <NavThree />
      <SliderOne />
      {/* <CourseCatThree /> */}
      <CourseCatOne />
      <BlogThree/>
      <CallToActionTwo/>
      <CallToActionOne />
      {/* <TestimonialOne /> */}
      {/* <VideoThree /> */}
      
      <Footer />
    </Layout>
  );
};

export default HomePageThree;
